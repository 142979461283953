.mainwrapper{
    width:100vw;
    height:100vh;
    background-repeat:no-repeat;
    background-size: cover;
    background-image:url("../images/stars2.png");
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 10px 20px; */
    position: relative;
    overflow: hidden;
  }
  
  .title{
    font-family: var(--font-pure-joy);
    color: white;
    text-shadow:0px 10px black;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .body{
    display: flex;
  }
  
  .description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-barrio);
    color: white;
    line-height: 35px;
    margin: 15px;
    z-index: 1;
    align-self: center;
    box-sizing: border-box;
  }
  
  .framescontainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .framewrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-width: 10px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 10px;
    border: 5px solid #000116;
    position: relative;
  }
  
  .frame{
    margin: 15px;
    box-sizing: border-box;
  }
  
  .frametitle{
    font-family: var(--font-barrio);
    margin: 5px;
    box-sizing: border-box;
    color: white;
    text-align: end;
  }
  
  .framesubtitle{
    font-family: var(--font-patrick-hand);
    margin: 0;
    box-sizing: border-box;
    color: white;
  }
  
  .marv_beach,.marv_pool{
    height: auto;
    position: absolute;
  }

  .description_text{
    font-family: var(--font-patrick-hand);
    color: white;
    margin: 0px;
    line-height: 40px;
  }
  
  @media screen and (max-width:500px) {
    .title{
      font-size: 50px;
      height: 15%;
    }
  
    .mainwrapper{
      max-height: 1050px;
      min-height: 1000px;
      min-width: 320px;
    }
  
    .subwrapper{
      min-width: 320px;
      overflow: hidden;
    }
  
    .body{
      flex-direction: column;
      height: 85%;
    }
  
    .description{
      height: 35%;
      width: 100%;
      font-size: 22px;
      line-height: 25px;
      text-align: center;
    }
  
    .framescontainer{
      width: 100%;
      height: 60%;
    }
  
    .frame{
      width: 100%;
      height: 100%;
    }
  
    .frameimage{
      width: 100px;
    }
  
    .marv_beach,.marv_pool{
      display: none;
    }
  }
  
  @media screen and (min-width:501px) and (max-width:1024px) {
    .title{
      font-size:74px;
      height: 15%;
    }
  
    .mainwrapper{
      max-height: 1100px;
      min-height: 1000px;
    }
  
    .subwrapper{
      min-width: 320px;
    }
  
    .body{
      flex-direction: column;
      height: 85%;
    }
  
    .description{
      height: 35%;
      width: 100%;
      font-size: 26px;
      text-align: center;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .framescontainer{
      width: 100%;
      height: 60%;
    }
  
    .frame{
      width: 150px;
      height: 150px;
    }
  
    .frameimage{
      width: 150px;
    }
  
    .marv_beach{
      top: 20px;
      width: 125px;
      left: -20px;
    }
  
    .marv_pool{
      top: 10px;
      width: 100px;
      right: -20px;
    }
  
  }
  
  @media screen and (min-width:1025px) {
      .title{
        font-size: 140px;
        height:30%;
      }
  
      .mainwrapper{
        max-height: 1000px;
        min-height: 900px;
      }
    
      .subwrapper{
        max-width: 2000px;
      }
  
      .body{
        flex-direction: row;
        gap: 2%;
        height: 70%;
        box-sizing: border-box;
        padding: 0% 5%;
      }
  
      .description{
        height: 100%;
        width: 48%;
        font-size: 30px;
        text-align: start;
        line-height:60px;
      }
  
      .framescontainer{
        width: 50%;
        height: 100%
      }
  
      .frame{
        width: 200px;
        height: 200px;
      }
  
      .frameimage{
        width: 200px;
      }
  
      .marv_beach{
        top: 80px;
        width: 125px;
        left: 10px;
      }
  
      .marv_pool{
        top: 20px;
        width: 130px;
        right: 10px;
      }
  
  }
  
  /* .marvnomics1 {
    line-height: 50px;
  }
  .marvnomics1,
  .marvsJourneyIn,
  .polaMarv0002Icon {
    align-self: stretch;
    position: relative;
  }
  .marvsJourneyIn {
    font-size: 28px;
    line-height: 34px;
    font-family: var(--font-patrick-hand);
    color: var(--color-gray-100);
  }
  .polaMarv0002Icon {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .marvAndPolaImage {
    width: 320px;
    height: 233.3px;
    margin: 0 !important;
    position: absolute;
    top: -41px;
    left: -262px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    z-index: 1;
  }
  .firstPage0005Icon {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
  }
  .firstPageImage {
    width: 468px;
    height: 338.6px;
    margin: 0 !important;
    position: absolute;
    top: -200px;
    right: -261px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    max-width: 100%;
    z-index: 1;
  } */
  .parent-container {
    display: flex;
    justify-content: center; /* Center the child horizontally */
    width: 100%; /* Ensure the parent takes up the full width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  .conteTWrapper {
    max-width: 1200px; /* Set a maximum width */
    width: 100%; /* Ensure it takes up the full width up to the maximum */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    margin: 0 auto; /* Center the container */
    padding: 0 20px; /* Adjust padding as needed */
  }
  
  .conteT {
    width: 100%; /* Take full width of parent */
    max-width: 803px; /* Limit max width */
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-7xl);
    text-align: center;
    display: flex;
  }
  
  .marvWebNewSet0002 {
    width: 154px;
    height: 154px;
    position: relative;
    object-fit: contain;
  }
  .burned,
  .liquidity {
    width: 79px;
    height: 24px;
    position: relative;
    line-height: 76.8px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .burned {
    width: 136px;
    height: 37px;
    font-size: var(--font-size-17xl);
    font-family: var(--font-pure-joy);
  }
  .marvWebNewSet0002Parent {
    height: 269px;
    width: 275px;
    border-radius: var(--br-25xl);
    background: linear-gradient(90deg, #ece9e6, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-6xs) var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-smi);
  }
  .frameParent {
    width: 589px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 36px 39px;
    min-width: 589px;
    min-height: 574px;
    max-width: 100%;
    flex-shrink: 0;
  }
  .marvMems00011 {
    width: 487px;
    position: relative;
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
    flex-shrink: 0;
  }
  .choose,
  .tokenomicsDetails {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }
  .choose {
    width: 588.3px;
    display: none;
    justify-content: center;
    gap: var(--gap-71xl);
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--color-darkslategray);
    font-family: var(--font-patrick-hand);
  }
  .tokenomicsDetails {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 var(--padding-8xl) 0 0;
    box-sizing: border-box;
    gap: 36px 39px;
    min-width: 400px;
    min-height: 574px;
  }
  .marvMems000111 {
    width: 487px;
    position: relative;
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
  }
  .tokenomics {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-71xl);
    max-width: 100%;
  }
  .marvnomics {
    width: 100%;
    background-color: var(--color-seagreen);
    border-bottom: 3px solid var(--polaonbasecom-black);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-110xl) var(--padding-91xl) var(--padding-96xl)
      var(--padding-118xl);
    gap: var(--gap-22xl);
    
    text-align: center;
    font-size: var(--font-size-29xl);
    color: var(--polaonbasecom-nero);
    font-family: var(--font-acme);
  }
  @media screen and (max-width: 1350px) {
    .frameParent,
    .marvMems00011 {
      flex: 1;
    }
    .choose {
      flex-wrap: wrap;
    }
    .marvMems000111 {
      flex: 1;
    }
    .tokenomics {
      flex-wrap: wrap;
      gap: var(--gap-26xl);
    }
  }
  @media screen and (max-width: 1125px) {
    .frameParent {
      min-width: 100%;
    }
    .marvnomics {
      padding-top: var(--padding-65xl);
      padding-bottom: var(--padding-56xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 800px) {
    .marvnomics1 {
      font-size: var(--font-size-19xl);
      line-height: 40px;
    }
    .burned {
      font-size: var(--font-size-10xl);
      line-height: 61px;
    }
    .tokenomicsDetails {
      gap: var(--gap-lgi);
      min-width: 100%;
    }
    .tokenomics {
      gap: var(--gap-3xl);
    }
    .marvnomics {
      gap: var(--gap-xl);
      padding: var(--padding-36xl) var(--padding-36xl) var(--padding-30xl)
        var(--padding-49xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .marvnomics1 {
      font-size: var(--font-size-10xl);
      line-height: 30px;
    }
    .marvsJourneyIn {
      font-size: var(--font-size-base);
      line-height: 27px;
    }
    .liquidity {
      font-size: var(--font-size-lgi);
      line-height: 61px;
    }
    .burned {
      font-size: var(--font-size-3xl);
      line-height: 46px;
    }
    .marvnomics {
      padding-left: var(--padding-xl);
      padding-right: var(--padding-xl);
      box-sizing: border-box;
    }
  }
  