@import url('https://fonts.googleapis.com/css2?family=Average+Sans&family=Bahiana&family=Barriecito&family=Barrio&family=Bubbler+One&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Da:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand:wght@400&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-pure-joy: "Pure Joy";
  --font-bahiana:Bahiana;
  --font-barrio:Barrio;
  --font-barriecito:Barrio;
  --font-bubbler:"Bubbler One";
  --font-average:"Average Sans";

  --font-patrick-hand: "Patrick Hand";
  --font-patrick-hand-SC: "Patrick Hand SC";
  --font-baloo-da: "Baloo Da";
  --font-annie-use-your-telescope: "Annie Use Your Telescope";
}

@font-face {
  font-family: "Pure Joy";
  src: url("/public/Pure Joy.ttf");
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
