.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-size: cover;
    background-image:url("../images/stars.png");
    position: relative;
    overflow: hidden;
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .body{
    display: flex;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    flex-direction: column;
    padding-top:60px;
  }

  .banner{
    position: absolute;
    width: 100%;
    left: 0;
    object-fit:cover;
    z-index: 1;
    top: 0%;
  }

  .titlewrapper{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  }

  .imageswrapper{
    display: grid;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    border-color: black;
  }

  .bg{
    object-fit: contain;
    height: 100%;
    position: absolute;
    /* content: url("../images/Section2/bg.png"); */
  }

  .title{
    color: white;
    font-family: var(--font-pure-joy);
    margin: 0;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height:450px;
        min-height:400px;
    }

    .body{
        padding: 30px;
        padding-top:75px;
        gap:25px;
    }

    .banner{
        height: 75px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    
    .image{
        width:100px;
        border-width: 10px;
    }

    .title{
        font-size: 70px;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height:650px;
        min-height:600px;
    }

    .body{
        padding: 30px;
        padding-top: 100px;
        gap:25px;
    }

    .banner{
        height: 100px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    
    .image{
        width:150px;
        border-width: 10px;
    }

    .title{
        font-size: 80px;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 1100px;
        min-height: 1050px;
    }

    .body{
        padding: 100px;
        padding-top: 150px;
        gap: 75px;
    }

    .banner{
        height: 150px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    
    .image{
        width: 300px;
        border-width: 10px;
    }

    .title{
        font-size: 120px;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1250px;
        min-height: 1200px;
    }

    .subwrapper{
        max-width: 2200px;
    }

    .body{
        padding: 100px;
        padding-top: 150px;
        gap: 75px;
    }

    .banner{
        height: 150px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px;
    }
    
    .image{
        width:350px;
        border-width: 10px;
    }  

    .title{
        font-size: 140px;
    }

  }

